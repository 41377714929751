import Bugsnag from '@bugsnag/js';
import { render } from 'preact';
import React from 'preact/compat';
import { App } from './app.tsx';
import './index.css';
import registerServiceWorker from 'registerServiceWorker.ts';
import * as dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import { parseStartParam } from 'common/utils/parseStartParam.ts';
import { sendReferralCode } from 'frens/frens.store.ts';
import './bugsnag.ts';
import { gtag } from 'gtag.ts';
import WebApp from '@twa-dev/sdk';
import { releaseStage } from 'common/constants';

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

declare global {
    interface Window {
        tonConnectUI: import('@tonconnect/ui').TonConnectUI;
        tonkeeper: {
            tonconnect: any;
        };
    }
}

registerServiceWorker();
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

async function main() {
    const startParams = parseStartParam(WebApp.initDataUnsafe.start_param);

    if (startParams?.referralCode) {
        await sendReferralCode(startParams.referralCode);
    }

    gtag('setTelegramID', {});

    if (releaseStage === 'development') {
        window.tonkeeper = {
            tonconnect: {},
        };
    }

    render(
        <ErrorBoundary>
            <App />
        </ErrorBoundary>,
        document.getElementById('app')!
    );
}

main();
