import { useTranslation } from 'i18n';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { WalletConnectionWrapper } from 'tonConnect/walletConnectionWrapper.tsx';
import { navigation } from 'common/utils/routeUtils.ts';
import { openExternalLink } from 'common/utils/openExternalLink.ts';

export const AboutTab = () => {
    const { t } = useTranslation();
    return (
        <div className="h-full overflow-y-auto">
            <div className="p-5 flex flex-col gap-5">
                <TextWithShadow className="text-center italic">
                    {t('cabal.about.title').toUpperCase()}
                </TextWithShadow>
                <div className="w-full flex flex-col items-center">
                    <img src="/cabal/pepe-delight.png" alt="" className="w-[100px]" />
                    <div className="bg-[#E6F0FF] w-full p-5">
                        <span className="text-2xl font-PressStart2P text-main-blue">1</span>
                        <div className="border-l-2 border-main-blue ml-3.5 mt-1 pl-5 mb-5">
                            <p
                                className="text-sm font-bold mb-3"
                                dangerouslySetInnerHTML={{
                                    __html: t('cabal.about.team').replace(
                                        /<>(.*?)<\/>/gi,
                                        '<span class="text-main-blue">$1</span>'
                                    ),
                                }}
                            />
                            <p className="text-sm font-normal">{t('cabal.about.team.quote')}</p>
                        </div>
                        <span className="text-2xl font-PressStart2P text-main-blue">2</span>
                        <div className="border-l-2 border-main-blue ml-3.5 mt-1 pl-5 mb-5">
                            <p
                                className="text-sm font-bold mb-3"
                                dangerouslySetInnerHTML={{
                                    __html: t('cabal.about.launch').replace(
                                        /<>(.*?)<\/>/gi,
                                        '<span class="text-main-blue">$1</span>'
                                    ),
                                }}
                            />
                            <p className="text-sm font-normal">{t('cabal.about.launch.quote')}</p>
                        </div>
                        <div className="bg-[#D4E3FA] p-5">
                            <p className="font-normal text-sm mb-5">
                                {t('cabal.about.bigpump-support')}
                            </p>
                            <button
                                className="flex justify-center gap-1 border-2 border-main-blue p-2 w-full text-2xs text-center text-black"
                                onClick={() => openExternalLink('https://cabal-arena.fun/')}
                            >
                                {t('cabal.about.register')}
                            </button>
                        </div>
                    </div>
                </div>
                <WalletConnectionWrapper>
                    <BigColorButton
                        variant="yellow"
                        className="h-12"
                        onClick={navigation.createToken}
                    >
                        {t('cabal.about.create-memecoin').toUpperCase()}
                    </BigColorButton>
                </WalletConnectionWrapper>
            </div>
        </div>
    );
};
