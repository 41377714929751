import { TickerLabel } from 'tokens/components/tickerLabel.tsx';
import { Card } from 'common/components/card.tsx';
import { useCoinById } from 'tokens/token.store.ts';

export const TokenHeader = () => {
    const { coin } = useCoinById();

    if (!coin) {
        return null;
    }

    return (
        <Card className="!bg-purple !p-3 flex items-center gap-2 w-full">
            <img src={coin.imageUrl} alt="history" className="w-6 h-6" />
            <span className="text-sm text-white mr-auto">{coin.name}</span>
            <TickerLabel>{coin.symbol}</TickerLabel>
        </Card>
    );
};
