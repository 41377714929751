import { FC } from 'preact/compat';

type Props = {
    size?: 'sm' | 'md' | 'lg';
    height?: number | string;
};

const sizes = {
    sm: 'h-8',
    md: 'h-12',
    lg: 'h-16',
};

export const Skeleton: FC<Props> = ({ size = 'md', height }) => {
    return <div className={`animate-pulse ${sizes[size]} bg-slate-700`} style={{ height }}></div>;
};
