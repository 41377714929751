import classNames from 'classnames';

export type Tab<T> = {
    label: string;
    value: T;
    alwaysHighlighted?: boolean;
    icon?: string;
};

type TabsProps<T> = {
    tabs: Tab<T>[];
    activeTab: T;
    onChange: (tab: T) => void;
    fitContent?: boolean;
    tabClassName?: string;
};

export const Tabs = <T extends string | number>({
    tabs,
    activeTab,
    onChange,
    fitContent,
    tabClassName,
}: TabsProps<T>) => {
    return (
        <div
            className={classNames(
                'flex items-center justify-between gap-1 overflow-x-auto no-scrollbar py-3',
                {
                    'w-full': !fitContent,
                }
            )}
        >
            {tabs.map((tab) => (
                <button
                    className={classNames(
                        'border border-black bg-gradient-to-b text-xs px-1 py-[5px] whitespace-nowrap text-center flex justify-center items-center leading-4 h-7 rounded-[10px]',
                        {
                            'from-[#FFB000] to-[#FFD16B] shadow-[0px_-3px_0px_0px_#305294]':
                                activeTab === tab.value,
                            'from-[#4A6FB7] to-[#305294] text-white shadow-[0px_3px_0px_0px_#305294]':
                                activeTab !== tab.value,
                            '!border-[#FFD16B]': activeTab !== tab.value && tab.alwaysHighlighted,
                            'flex-1': !fitContent,
                        },
                        tabClassName
                    )}
                    onClick={() => onChange(tab.value)}
                >
                    {tab.icon && <img src={tab.icon} alt="" className="w-5 h-5 min-w-[20px]" />}
                    {tab.label}
                </button>
            ))}
        </div>
    );
};
