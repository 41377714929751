import { ReactNode } from 'preact/compat';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { clearCoin, fetchCoinById, useCoinById } from 'tokens/token.store.ts';
import { useBackButton } from 'common/utils/tgUtilt.ts';
import { navigation } from 'common/utils/routeUtils.ts';
import { useEffect } from 'preact/hooks';

type TokenPageProps = {
    tokenId: string;
    children?: ReactNode;
};

export const TokenPage = ({ tokenId, children }: TokenPageProps) => {
    const { coin } = useCoinById();
    useAutoFetch(() => fetchCoinById(tokenId), 5000);
    useBackButton({
        from: `/token/${tokenId}/trade`,
        callback: navigation.mainPage,
    });

    useEffect(() => {
        if (coin && coin.id !== tokenId) {
            clearCoin();
        }
    }, [tokenId]);

    return <div className="flex flex-col gap-2.5 relative">{children}</div>;
};
