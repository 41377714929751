import { ReactNode } from 'preact/compat';
import { useState } from 'preact/hooks';

type TooltipProps = {
    text?: ReactNode;
    className?: string;
    isOpen?: boolean;
    children?: ReactNode;
    containerClassName?: string;
};

export const Tooltip = ({
    isOpen,
    text,
    className,
    children,
    containerClassName,
}: TooltipProps) => {
    const isExternalValue = typeof isOpen !== 'undefined';

    const [open, setOpen] = useState(false);
    const toggleOpen = () => {
        if (!isExternalValue) {
            setOpen((value) => !value);
        }
    };

    const state = typeof isOpen !== 'undefined' ? isOpen : open;

    return (
        <>
            <div className={`w-fit relative ${containerClassName}`} onClick={toggleOpen}>
                {children}
                {state && (
                    <span
                        className={`absolute bg-white text-black rounded-md text-xs border border-black px-2 py-1 top-[-30px] left-1/2 transform -translate-x-1/2 shadow z-50 ${className}`}
                    >
                        {text}
                    </span>
                )}
            </div>
            {open && (
                <div className="fixed top-0 left-0 w-screen h-screen" onClick={toggleOpen}></div>
            )}
        </>
    );
};
