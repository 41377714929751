import { FC, JSX } from 'preact/compat';

export type ButtonVariants =
    | 'yellow'
    | 'white'
    | 'blueGradient'
    | 'darkYellowGradient'
    | 'oxidGreen';

type ButtonProps = JSX.HTMLAttributes<HTMLButtonElement> & {
    variant?: ButtonVariants;
};

const buttonBg: Record<ButtonVariants, string> = {
    yellow: 'bg-yellow',
    white: 'bg-white',
    blueGradient: 'bg-light-blue bg-gradient-to-b from-transparent via-white/40 to-transparent',
    darkYellowGradient:
        'bg-dark-yellow bg-gradient-to-b from-transparent via-white/20 to-transparent',
    oxidGreen: 'bg-oxid-green-v2',
};

const buttonText: Record<ButtonVariants, string> = {
    yellow: 'text-black',
    white: 'text-black',
    blueGradient: 'text-white',
    darkYellowGradient: 'text-black',
    oxidGreen: 'text-black',
};

export const Button: FC<ButtonProps> = ({
    children,
    variant = 'white',
    className,
    style,
    ...props
}) => {
    return (
        <button
            className={`
            ${buttonBg[variant]}
            ${buttonText[variant]}
            p-2
            cursor-pointer
            disabled:cursor-not-allowed
            disabled:opacity-50
            border-[1px]
            text-xs
            leading-none
            ${className}
            `}
            style={{
                background: buttonBg[variant],
                ...(typeof style === 'object' ? style : {}),
            }}
            {...props}
        >
            {children}
        </button>
    );
};
