import 'createToken/components/index.css';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { CreateCoinScreenProps } from 'createToken/createCoin.page.tsx';
import { setCoinDescription, setCoinSocial, useCreateCoin } from 'createToken/createCoin.store.ts';
import { CreateCoinInput } from 'createToken/components/createCoinInput.tsx';
import { useKeyboardOpen } from 'common/hooks/useKeyboardOpen.ts';
import { ScreenLayout } from 'createToken/components/screenLayout.tsx';
import { ImageContainer } from 'createToken/components/imageContainer.tsx';
import classNames from 'classnames';
import { useState } from 'preact/hooks';
import { tgChannelRegex, twitterRegex, websiteRegex } from 'createToken/config.ts';
import { Social, SocialType } from 'createToken/components/social.tsx';
import { useTranslation } from 'i18n';

const maxNameLimit = 280;

export const DescriptionScreen = ({ goNext }: CreateCoinScreenProps) => {
    const { t } = useTranslation();
    const { description, twitter, website, tgChannel } = useCreateCoin();

    const { isKeyboardOpen, handleFocus, handleBlur } = useKeyboardOpen();

    const canGoNext =
        description.length &&
        description.length <= maxNameLimit &&
        validateSocial(twitter, 'twitter') &&
        validateSocial(website, 'website') &&
        validateSocial(tgChannel, 'tgChannel') &&
        !isKeyboardOpen;

    const onSubmit = () => {
        if (
            !description ||
            description.length > maxNameLimit ||
            !validateSocial(twitter, 'twitter') ||
            !validateSocial(website, 'website') ||
            !validateSocial(tgChannel, 'tgChannel')
        ) {
            return;
        }

        goNext();
    };

    return (
        <ScreenLayout isKeyboardOpen={isKeyboardOpen} fullHeight>
            <ImageContainer
                src={'/coin_description.gif'}
                isKeyboardOpen={isKeyboardOpen}
                imgClassName={canGoNext && isKeyboardOpen ? '!h-[50px]' : '!h-[100px]'}
                imgContainerClassName="!h-auto"
            />

            <CreateCoinInput
                limit={maxNameLimit}
                onChange={setCoinDescription}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={description}
                placeholder={t('createToken.description').toUpperCase()}
                type="textarea"
            />

            <div className="flex w-full flex-col gap-2.5">
                <p
                    className="text-2xs text-white text-center"
                    dangerouslySetInnerHTML={{
                        __html: t('createToken.socials.description').replace(/\n/g, '<br />'),
                    }}
                />
                <Socials handleFocus={handleFocus} handleBlur={handleBlur} />
            </div>

            <BigColorButton
                variant="yellow"
                className={classNames('py-2 transition-all', {
                    [`!h-0 !opacity-0`]: !canGoNext,
                    [`!h-[68px]`]: canGoNext,
                })}
                onClick={onSubmit}
                disabled={!canGoNext}
            >
                <div className="flex items-center gap-1">
                    <TextWithShadow>{t('createToken.next').toUpperCase()}</TextWithShadow>
                    <img src="/arrow_right.gif" alt="" className="h-7" />
                </div>
            </BigColorButton>
        </ScreenLayout>
    );
};

const placeholderByType = {
    tgChannel: 'createToken.telegram.placeholder',
    tgChat: 'createToken.telegramChat.placeholder',
    twitter: 'createToken.twitter.placeholder',
    website: 'createToken.website.placeholder',
} as const;

const validateSocial = (value: string, type: SocialType) => {
    if (!value) {
        return true;
    }
    switch (type) {
        case 'tgChannel':
        case 'tgChat':
            return tgChannelRegex.test(value);
        case 'twitter':
            return twitterRegex.test(value);
        case 'website':
            return websiteRegex.test(value);
    }
};

const Socials = ({
    handleFocus,
    handleBlur,
}: {
    handleFocus: () => void;
    handleBlur: () => void;
}) => {
    const { t } = useTranslation();
    const [activeSocial, setActiveSocial] = useState<SocialType | null>(null);
    const values = useCreateCoin();
    return (
        <div className="bg-black rounded-xl p-1 w-full">
            <div className="flex items-center justify-between gap-1">
                <Social
                    type={'tgChannel'}
                    active={activeSocial === 'tgChannel'}
                    filled={!!values.tgChannel}
                    onClick={setActiveSocial}
                    error={!validateSocial(values.tgChannel, 'tgChannel')}
                    dialogCorner={activeSocial === 'tgChannel'}
                />
                <Social
                    type={'tgChat'}
                    active={activeSocial === 'tgChat'}
                    filled={!!values.tgChat}
                    onClick={setActiveSocial}
                    error={!validateSocial(values.tgChat, 'tgChat')}
                    dialogCorner={activeSocial === 'tgChat'}
                />
                <Social
                    type={'twitter'}
                    active={activeSocial === 'twitter'}
                    filled={!!values.twitter}
                    onClick={setActiveSocial}
                    error={!validateSocial(values.twitter, 'twitter')}
                    dialogCorner={activeSocial === 'twitter'}
                />
                <Social
                    type={'website'}
                    active={activeSocial === 'website'}
                    filled={!!values.website}
                    onClick={setActiveSocial}
                    error={!validateSocial(values.website, 'website')}
                    dialogCorner={activeSocial === 'website'}
                />
            </div>
            {activeSocial && (
                <CreateCoinInput
                    value={values[activeSocial]}
                    placeholder={t(placeholderByType[activeSocial])}
                    limit={100}
                    onChange={(value) => setCoinSocial(value, activeSocial)}
                    error={!validateSocial(values[activeSocial], activeSocial)}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                />
            )}
        </div>
    );
};
