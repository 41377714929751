export const getSearchParams = () => {
    let params = new URLSearchParams(window.location.search);
    return params;
};

export const getAccountIndexSearchParams = (): number => {
    const params = getSearchParams();
    const selectedWalletIndex = params.get('selectedWalletIndex');
    console.log('params', params, selectedWalletIndex, window.location.search);
    return selectedWalletIndex ? parseInt(selectedWalletIndex) : 0;
};

export const getTradeIndexSearchParams = (): string | null => {
    const params = getSearchParams();
    const selectedTradeIndex = params.get('tradeID');
    return selectedTradeIndex;
};
