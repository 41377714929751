import { User } from 'user/user.store.ts';
import { botName } from 'common/constants';
import WebApp from '@twa-dev/sdk';

export const linkData = {
    stage: {
        appName: 'wasd',
        bot: 'aqua_cinnamon_bot',
    },
    production: {
        appName: 'bigpump',
        bot: 'pocketfi_bot',
    },
};

const env = import.meta.env.VITE_APP_ENV as 'stage' | 'production';
const referralParams = linkData[env] ?? linkData.stage;

export const generateReferralLink = ({
    user,
    params,
    appName = referralParams.appName,
    bot = botName,
}: {
    user?: User;
    appName?: string;
    params?: Record<string, string>;
    bot?: string;
}) => {
    if (!user) {
        return 'An error occurred while generating referral link (USER NOT FOUND)';
    }
    const link = `https://t.me/${bot}/${appName}?startapp=${user.referralCode}`;
    if (params) {
        const stringParams = btoa(JSON.stringify(params));
        return link + '-' + stringParams;
    }
    return link;
};

export const openShareWithFriendsLink = (url: string, text?: string) => {
    let link = `https://t.me/share/url?url=${encodeURIComponent(url)}`;

    if (text) {
        link += `&text=${encodeURIComponent(text)}`;
    }

    WebApp.openTelegramLink(link);
};
