import { FC } from 'preact/compat';
import classNames from 'classnames';
import { useTranslation } from 'i18n';

type TokenCreatedByProps = {
    className?: string;
    children?: string;
};

/**
 * TokenCreatedBy component, show token creator
 * @param className - Wrapper class
 * @param children - Creator
 * @constructor
 */
export const TokenCreatedBy: FC<TokenCreatedByProps> = ({ className, children }) => {
    const { t } = useTranslation();

    return (
        <div className="flex items-center gap-1">
            <img src="/heart.gif" alt="heart" className="w-4 min-w-[1rem]" />
            <span className={classNames('text-2xs text-white', className)}>
                {t('token-created-by')}: {children ? `@${children}` : t('anonymous').toLowerCase()}
            </span>
        </div>
    );
};
