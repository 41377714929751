import WebApp from '@twa-dev/sdk';

export const gtag = (type: string, data: Record<string, string | number>) => {
    try {
        if ('dataLayer' in window && Array.isArray(window.dataLayer)) {
            window.dataLayer.push({
                event: type,
                user_id: WebApp.initDataUnsafe.user?.id ?? 'unknown',
                ...data,
            });
        }
    } catch (e) {
        console.error('gtag error', e);
    }
};
