import { useState } from 'preact/hooks';
import { Drawer } from 'common/components/drawer.tsx';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';
import { Tab, Tabs } from 'common/components/tabs.tsx';
import { setBuySlippage, setSellSlippage, useSlippage } from 'tokens/slippage/slippage.store.ts';
import { useTranslation } from 'i18n';

type Slippage = 10 | 25 | 30 | 50 | 99;

const slippageTabs: Tab<Slippage>[] = [
    { label: '10%', value: 10 },
    { label: '25%', value: 25 },
    { label: '30%', value: 30 },
    { label: '50%', value: 50 },
    { label: '99%', value: 99 },
];

type SlippageSettingsProps = {
    type: 'buy' | 'sell';
};

export const SlippageSettings = ({ type }: SlippageSettingsProps) => {
    const { t } = useTranslation();
    const currentSlippage = useSlippage(type);
    const [isSettingOpen, setIsSettingOpen] = useState(false);

    const onChange = (value: number) => {
        if (type === 'buy') {
            setBuySlippage(value);
        } else {
            setSellSlippage(value);
        }
    };

    return (
        <>
            <button
                className="flex items-center gap-2 text-white"
                onClick={() => setIsSettingOpen(true)}
            >
                <img src="/filters.svg" alt="" className="w-4" />
                <span className="text-xs font-normal">
                    {t('buy-sell.slippage')} {Number(currentSlippage)}%
                </span>
            </button>
            <Drawer open={isSettingOpen} onClose={() => setIsSettingOpen(false)} position="bottom">
                <div className="flex flex-col gap-5 items-center px-5">
                    <TextWithShadow className="italic">
                        {t('buy-sell.slippage').toUpperCase()}
                    </TextWithShadow>
                    <Tabs
                        tabs={slippageTabs}
                        activeTab={Number(currentSlippage) as Slippage}
                        onChange={onChange}
                        fitContent
                        tabClassName="py-1.5 w-12"
                    />
                    <p className="text-sm text-white font-normal text-center mb-5">
                        {t('buy-sell.slippage.description')}
                    </p>
                </div>
            </Drawer>
        </>
    );
};
