import { FC, ReactNode } from 'preact/compat';
import { Button, ButtonVariants } from './button.tsx';
import { useState } from 'preact/hooks';
import { useTranslation } from 'i18n';
import { Tooltip } from 'common/components/tooltip.tsx';

type CopyButtonProps = {
    text?: string;
    className?: string;
    variant?: ButtonVariants;
    children?: ReactNode;
    tooltipContainerClassName?: string;
    iconColor?: 'white' | 'black';
};

export const CopyButton: FC<CopyButtonProps> = ({
    text,
    className,
    variant,
    children,
    tooltipContainerClassName,
    iconColor = 'white',
}) => {
    const { t } = useTranslation();
    const [tooltip, setTooltip] = useState('');
    const onClick = () => {
        if (!text) {
            setTooltip(t('copy-error'));
            setTimeout(() => {
                setTooltip('');
            }, 1000);
            return;
        }
        try {
            navigator.clipboard.writeText(text);
            setTooltip(t('copied'));
        } catch (e) {
            console.log('Error copying to clipboard', e);
            setTooltip(t('copy-error'));
        } finally {
            setTimeout(() => {
                setTooltip('');
            }, 1000);
        }
    };
    return (
        <Tooltip isOpen={!!tooltip} text={tooltip} containerClassName={tooltipContainerClassName}>
            <div onClick={onClick} className="inline">
                {children ?? (
                    <Button className={className} variant={variant} disabled={!text}>
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <mask
                                id="mask0_344_445"
                                style="mask-type:alpha"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="24"
                                height="24"
                            >
                                <rect width="24" height="24" fill={iconColor} />
                            </mask>
                            <path
                                d="M9 18C8.45 18 7.97917 17.8042 7.5875 17.4125C7.19583 17.0208 7 16.55 7 16V4C7 3.45 7.19583 2.97917 7.5875 2.5875C7.97917 2.19583 8.45 2 9 2H18C18.55 2 19.0208 2.19583 19.4125 2.5875C19.8042 2.97917 20 3.45 20 4V16C20 16.55 19.8042 17.0208 19.4125 17.4125C19.0208 17.8042 18.55 18 18 18H9ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V7C3 6.71667 3.09583 6.47917 3.2875 6.2875C3.47917 6.09583 3.71667 6 4 6C4.28333 6 4.52083 6.09583 4.7125 6.2875C4.90417 6.47917 5 6.71667 5 7V20H15C15.2833 20 15.5208 20.0958 15.7125 20.2875C15.9042 20.4792 16 20.7167 16 21C16 21.2833 15.9042 21.5208 15.7125 21.7125C15.5208 21.9042 15.2833 22 15 22H5Z"
                                fill={iconColor}
                            />
                        </svg>
                    </Button>
                )}
            </div>
        </Tooltip>
    );
};
