import { z } from 'zod';

type FormValidationErrors<T> = Partial<Record<keyof T, string>>;

export function createValidator<T extends z.ZodType>(schema: T) {
    return async (values: z.infer<T>): Promise<FormValidationErrors<z.infer<T>>> => {
        const errors: FormValidationErrors<z.infer<T>> = {};
        const response = schema.safeParse(values);

        if (!response.success) {
            response.error.issues.forEach((issue) => {
                const key = issue.path[0];
                if (typeof key !== 'string') {
                    throw new Error(`No key in issue.path`);
                } else if (issue.path.length > 1) {
                    throw new Error(`No support for nested objects`);
                }
                errors[key as keyof z.infer<T>] = issue.message;
            });
        }

        return errors;
    };
}
