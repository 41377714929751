import { Button } from 'common/components/button.tsx';
import { useConnectionModal } from 'tonConnect/tonConnect.store.ts';
import { useTranslation } from 'i18n';

export const OpenTonConnectModal = () => {
    const { t } = useTranslation();
    const { open } = useConnectionModal();
    const openTonConnectModal = () => {
        open();
    };

    return (
        <Button className="border-red" onClick={openTonConnectModal}>
            {t('connect-wallet')}
        </Button>
    )
}
