import WebApp from '@twa-dev/sdk';
import { action, computed, map } from 'nanostores';
import { useStore } from '@nanostores/preact';

type KeyboardStore = {
    isOpen: boolean;
};

const $keyboardStore = map<KeyboardStore>({ isOpen: false });

export const setKeyboardState = action(
    $keyboardStore,
    'setKeyboardState',
    (store, state: boolean) => {
        store.setKey('isOpen', state);
    }
);

const selectKeyboardState = computed($keyboardStore, (store) => store.isOpen);

export const useKeyboardOpen = () => {
    const isFocused = useStore(selectKeyboardState);
    const handleFocus = () => setKeyboardState(true);
    const handleBlur = () => setKeyboardState(false);

    const isMobile =
        WebApp.platform === 'ios' ||
        WebApp.platform === 'android' ||
        WebApp.platform === 'android_x';

    return {
        handleFocus,
        handleBlur,
        isKeyboardOpen: isFocused && isMobile,
    };
};
