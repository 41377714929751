import { useEffect, useRef } from 'preact/hooks';

export const useScrollToFetchData = (fetchMoreData: () => void) => {
    const bottom = useRef(null);

    useEffect(() => {
        const bottomCurrent = bottom.current;
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    return;
                }
                fetchMoreData();
            });
        }, options);

        if (bottomCurrent) {
            observer.observe(bottomCurrent);
        }

        return () => {
            if (bottomCurrent) {
                observer.unobserve(bottomCurrent);
            }
        };
    }, [fetchMoreData, bottom.current]);

    return bottom;
};
