import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';
import { tonProofService } from 'tonkeeper/tonproof.service.ts';
import { setTonProofAuthorization } from 'tonkeeper/tonkeeper.store.ts';
import { ConnectedWallet } from '@tonconnect/ui';
import WebApp from '@twa-dev/sdk';
import { IS_TG } from 'common/constants';

export const initTonProofPayload = async () => {
    if (!window.tonkeeper || IS_TG) {
        return;
    }
    tonConnectUI.setConnectRequestParameters({ state: 'loading' });

    const payload = await tonProofService.generatePayload();

    if (payload) {
        tonConnectUI.setConnectRequestParameters({ state: 'ready', value: payload });
    } else {
        tonConnectUI.setConnectRequestParameters(null);
    }
};

export const onTonProofConnect = async (wallet: ConnectedWallet | null) => {
    if (!wallet) {
        tonProofService.reset();
        setTonProofAuthorization(false);
        return;
    }

    if (wallet.connectItems?.tonProof && 'proof' in wallet.connectItems.tonProof) {
        await tonProofService.checkProof(wallet.connectItems.tonProof.proof, wallet.account);
        return;
    }

    if (!tonProofService.accessToken) {
        tonConnectUI.disconnect();
        setTonProofAuthorization(false);
        return;
    }

    setTonProofAuthorization(true);
};
