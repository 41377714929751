import { cacheWorkerVersion } from 'common/constants';

export default function registerServiceWorker() {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', async () => {
            let isRegistered = false;
            await navigator.serviceWorker.getRegistrations().then((registrations) => {
                for (const registration of registrations) {
                    if (registration.active?.scriptURL.includes('cache-worker-v')) {
                        if (
                            !registration.active.scriptURL.includes(
                                `cache-worker-${cacheWorkerVersion}`
                            )
                        ) {
                            registration.unregister();
                        } else {
                            isRegistered = true;
                        }
                    }
                }
            });
            if (isRegistered) {
                console.log('Service Worker is already registered');
                return;
            }
            navigator.serviceWorker
                .register(`/cache-worker-${cacheWorkerVersion}.js`)
                .then((registration) => {
                    console.log('Service Worker registered:', registration);
                })
                .catch((error) => {
                    console.error('Error while registering Service Worker:', error);
                });
        });
    }
}
