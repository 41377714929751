import { TickerLabel } from 'tokens/components/tickerLabel.tsx';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { fetchActivity, useActivity } from 'main/main.store.ts';
import { displayNanoValue } from 'tokens/utils.ts';
import { TranslateFn, useTranslation } from 'i18n';
import { randomize } from 'common/utils/randomize.ts';
import { navigation } from 'common/utils/routeUtils.ts';

const mapBgColors = [
    'from-[#3A8200] via-[#91CB00] to-[#3A8200]',
    'from-[#E0002D] via-[#FF2E58] to-[#E0002D]',
    'from-[#0062FF] via-[#00C3FF] to-[#0062FF]',
];

const mapTextToTransactionType = (val: 'sell' | 'buy', t: TranslateFn) => {
    return val === 'sell' ? t('notification.sold') : t('notification.bought');
};

export const NotificationRow = () => {
    const { activity } = useActivity();
    useAutoFetch(fetchActivity, 5000);
    const { t } = useTranslation();

    if (!activity) {
        return <div className="bg-gray w-full h-[42px]"></div>;
    }

    const { user, transaction, coin } = activity;

    const getAmountToDisplay = () => {
        switch (activity.transaction.type) {
            case 'buy':
                return `${displayNanoValue(transaction.tonAmount, 9)} TON ${t('notification.of')}`;
            case 'sell':
                return `${displayNanoValue(transaction.coinsAmount, 9)}`;
        }
    };

    const navigateToCoinPage = () => {
        navigation.tokenTrade(coin.id)();
    };

    return (
        <div
            className={`bg-gradient-to-r ${mapBgColors[Math.round(randomize(0, 2))]} w-full px-5 py-3`}
            onClick={navigateToCoinPage}
        >
            <div className="flex items-center gap-1">
                <img src="/heart.gif" alt="heart" className="w-4 h-4 min-w-[1rem]" />
                <p className="text-white text-xs">
                    @{!user.username ? t('anonymous').toLowerCase() : user.username}{' '}
                    {mapTextToTransactionType(transaction.type, t)}&nbsp;
                    {getAmountToDisplay()}&nbsp;
                    <TickerLabel>{coin.symbol}</TickerLabel>
                </p>
                <img
                    src={coin.imageUrl}
                    alt="ticker"
                    className="w-[17px] h-[17px] min-w-[17px] inline-block mt-[1px]"
                />
            </div>
        </div>
    );
};
