import { Skeleton } from 'common/components/skeleton.tsx';
import { EmptyList } from 'main/emptyList.tsx';
import { Card } from 'common/components/card.tsx';
import { navigation } from 'common/utils/routeUtils.ts';
import dayjs from 'dayjs';
import { TickerLabel } from 'tokens/components/tickerLabel.tsx';
import { TokenMarketCap } from 'tokens/components/tokenMarketCap.tsx';
import { getCoinMarketCap } from 'tokens/utils.ts';
import { TokenCreatedBy } from 'tokens/components/tokenCreatedBy.tsx';
import { Progress } from 'common/components/progress.tsx';
import classNames from 'classnames';
import { fetchCoinsList, useCoinsList } from 'tokens/token.store.ts';
import { useScrollToFetchData } from 'common/hooks/useScrollLazyLoad.ts';
import { useTon } from 'main/main.store.ts';

const formatNewDuration = (str: string) => {
    const lang = dayjs().locale();

    if (lang === 'en') {
        return str
            .split(' ')
            .map((val, index) =>
                index === 1
                    ? val.startsWith('mo')
                        ? val.slice(0, 2)
                        : val.slice(0, 1)
                    : val !== 'a'
                      ? val
                      : 1
            )
            .join('');
    }

    return str
        .split(' ')
        .map((val, index, array) =>
            index === 1
                ? val.startsWith('ме')
                    ? val.slice(0, 3)
                    : val.slice(0, 1)
                : array.length > 1
                  ? val
                  : `1${val}`
        )
        .join('');
};

const cutDescription = (str?: string) => {
    if (!str) {
        return '';
    }
    return str.length > 65 ? `${str.slice(0, 65)}...` : str;
};

const isNew = (ts: string) => {
    return dayjs(ts).unix() * 1000 > Date.now() - 6 * 60 * 60 * 1000;
};

export const CoinsList = () => {
    const { coins, isFetching, canFetchMore, search, searchResults } = useCoinsList();

    const anchorRef = useScrollToFetchData(fetchCoinsList);

    const { price } = useTon();

    const coinsToDisplay = search && searchResults.length ? searchResults : coins;

    return (
        <>
            {isFetching && (
                <>
                    <Skeleton size="lg" height={150} />
                    <Skeleton size="lg" height={150} />
                    <Skeleton size="lg" height={150} />
                    <Skeleton size="lg" height={150} />
                    <Skeleton size="lg" height={150} />
                </>
            )}
            {!isFetching && !coinsToDisplay.length && <EmptyList />}
            {coinsToDisplay.map((coin) => (
                <Card className="flex flex-col gap-2 !p-3" onClick={navigation.tokenTrade(coin.id)}>
                    <div className="flex items-center gap-2">
                        <div className="relative">
                            <img
                                src={coin.imageUrl}
                                alt={coin.imageName}
                                className="min-w-[72px] w-[72px] max-h-[72px] object-cover object-center"
                            />
                            <span className="absolute left-0 bottom-0 bg-dark-green text-3xs text-white px-0.5 py-[1px]">
                                {isNew(coin.chainCreatedAt) ? `NEW ` : ''}
                                {formatNewDuration(
                                    dayjs
                                        .duration(dayjs(coin.chainCreatedAt).diff(dayjs()))
                                        .humanize()
                                )}
                            </span>
                        </div>
                        <div className="w-full flex flex-col gap-0.5">
                            <div className="flex items-center justify-between">
                                <span className="text-sm break-word">{coin.name}</span>
                                <TickerLabel>{coin.symbol}</TickerLabel>
                            </div>
                            <TokenMarketCap value={getCoinMarketCap(coin, price)} />
                            <p className="text-light-gray font-medium text-2xs break-word">
                                {cutDescription(coin.description)}
                            </p>
                            <TokenCreatedBy className="!text-black">
                                {coin.user.username}
                            </TokenCreatedBy>
                        </div>
                    </div>
                    <Progress progress={parseFloat(coin.bondingCurveProgressTon)} />
                </Card>
            ))}
            <div
                className={classNames('w-full flex flex-col gap-3', {
                    [`hidden`]: !canFetchMore || search,
                })}
                ref={anchorRef}
            >
                <Skeleton size="lg" height={150} />
                <Skeleton size="lg" height={150} />
                <Skeleton size="lg" height={150} />
                <Skeleton size="lg" height={150} />
                <Skeleton size="lg" height={150} />
            </div>
        </>
    );
};
