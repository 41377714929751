import { action, computed, deepMap } from 'nanostores';
import { useStore } from '@nanostores/preact';

type TonkeeperStore = {
    isAuthorized: boolean;
};

export const $tonProof = deepMap<TonkeeperStore>({
    isAuthorized: false,
});

// actions

export const setTonProofAuthorization = action(
    $tonProof,
    'setTonProofAuthorization',
    (store, value: boolean) => {
        store.setKey('isAuthorized', value);
    }
);

// selectors

const selectTonProof = computed($tonProof, (store) => store);

export const useTonProof = () => useStore(selectTonProof);
