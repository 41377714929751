import { useConnectionModal, useTonConnectConnectionStatus } from 'tonConnect/tonConnect.store.ts';
import { PropsWithChildren } from 'preact/compat';
import classNames from 'classnames';

type Props = {
    className?: string;
    containerClassName?: string;
};

export const WalletConnectionWrapper = ({
    children,
    className,
    containerClassName,
}: PropsWithChildren<Props>) => {
    const isConnected = useTonConnectConnectionStatus();
    const { open } = useConnectionModal();

    if (isConnected) {
        return <>{children}</>;
    }

    return (
        <div
            className={classNames(
                'relative flex items-stretch justify-stretch',
                containerClassName
            )}
        >
            {children}
            <button
                className={classNames('absolute z-50 top-0 left-0 w-full h-full', className)}
                onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    open();
                }}
            />
        </div>
    );
};
