import { FC, PropsWithChildren } from 'preact/compat';
import { Header } from './header.tsx';
import { useTonProof } from 'tonkeeper/tonkeeper.store.ts';
import { IS_TG } from 'common/constants';
import classNames from 'classnames';
import { OpenTonConnectModal } from 'tonConnect/openTonConnectModal.tsx';

type MainLayoutProps = {
    header?: PropsWithChildren['children'];
    color?: string;
    footer?: PropsWithChildren['children'];
};

export const MainLayout: FC<PropsWithChildren<MainLayoutProps>> = ({
    children,
    header,
    color,
    footer,
}) => {
    const { isAuthorized } = useTonProof();
    const shouldBlurMainScreen = !IS_TG && !isAuthorized;
    return (
        <div
            className="bg-new-background-primary h-dvh w-screen fixed top-0 left-0 overflow-y-auto overflow-x-hidden scroll-pt-16"
            style={{ backgroundColor: color }}
        >
            <div
                className={classNames({
                    'blur-lg': shouldBlurMainScreen,
                })}
            >
                <Header />
            </div>
            {header}
            <div
                className={classNames('p-2.5', {
                    'blur-lg': shouldBlurMainScreen,
                })}
            >
                {children}
            </div>
            {shouldBlurMainScreen ? (
                <div className="absolute z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <OpenTonConnectModal />
                </div>
            ) : null}
            {footer}
        </div>
    );
};
