import { FC, HTMLProps, PropsWithChildren } from 'preact/compat';

type CardProps = {
    className?: string;
} & HTMLProps<HTMLDivElement>;

export const Card: FC<PropsWithChildren<CardProps>> = ({ children, className, ...props }) => {
    return (
        <div className={`bg-white border-2 border-black p-5 shadow ${className}`} {...props}>
            {children}
        </div>
    );
};
