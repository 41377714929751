import { BigPumpSdk } from 'bclSdkOriginal/BigPumpSdk.ts';
import { computed, deepMap } from 'nanostores';
import { useStore } from '@nanostores/preact';
import { tonConnectUI } from 'tonConnect/configureTonConnect.ts';

type SdkStore = {
    sdk?: BigPumpSdk;
};

export const $sdk = deepMap<SdkStore>({});

export const initBigPumpSdk = async () => {
    try {
        const sdk = await BigPumpSdk.new(tonConnectUI);
        $sdk.setKey('sdk', sdk);
    } catch (e) {
        console.log('error on init sdk', e);

        // setTimeout(initBigPumpSdk, 1000);
    }
};

export const getSdk = () => $sdk.get().sdk;
const selectSdk = computed($sdk, (store) => store.sdk);

export const useBigPumpSdk = () => useStore(selectSdk);
