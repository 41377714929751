import { Card } from '../common/components/card.tsx';
import { TranslateFn, useTranslation } from 'i18n';
import { Button } from 'common/components/button.tsx';
import { Input } from 'common/components/input.tsx';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { navigation } from 'common/utils/routeUtils.ts';
import { KingOfHill } from 'main/kingOfHill.tsx';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import {
    clearCoin,
    fetchCoinsList,
    searchCoins,
    setCoinListSearch,
    setCoinListType,
    SortListType,
    useCoinsList,
} from 'tokens/token.store.ts';
import { useBackButton } from 'common/utils/tgUtilt.ts';
import { useTonConnectConnectionStatus } from 'tonConnect/tonConnect.store.ts';
import { CoinsList } from 'main/coinsList.tsx';
import { Tab, Tabs } from 'common/components/tabs.tsx';
import { useMemo } from 'preact/hooks';

//  Record<SortListType, string>
const mapTabNameWithType = (t: TranslateFn) => {
    return {
        [SortListType.Hot]: '',
        [SortListType.Featured]: '',
        [SortListType.TopMcap]: t('main.tabs.topMcap'),
        [SortListType.New]: t('main.tabs.new'),
        [SortListType.PocketFi]: t('main.tabs.pocketFi'),
        [SortListType.MyTokens]: t('main.tabs.myTokens'),
    };
};

const SORT_LIST_TABS = [
    SortListType.Hot,
    SortListType.Featured,
    SortListType.TopMcap,
    SortListType.New,
    SortListType.PocketFi,
    SortListType.MyTokens,
];

export const MainPage = () => {
    const { t } = useTranslation();
    const isWalletConnected = useTonConnectConnectionStatus();

    useBackButton({
        from: '/',
    });

    const { search, sortType } = useCoinsList();

    useAutoFetch(fetchCoinsList);

    useAutoFetch(clearCoin);

    const tabs: Tab<SortListType>[] = useMemo(() => {
        const getIcon = (tab: SortListType) => {
            switch (tab) {
                case SortListType.Hot:
                    return '/fire-flame.gif';
                case SortListType.Featured:
                    return '/slon.gif';
                default:
                    return undefined;
            }
        };

        return SORT_LIST_TABS.map((tab) => ({
            label: mapTabNameWithType(t)[tab],
            value: tab,
            alwaysHighlighted: tab === SortListType.Hot,
            icon: getIcon(tab),
        }));
    }, [t]);

    return (
        <div className="flex flex-col w-full gap-2.5">
            <KingOfHill />
            <div className={`grid ${isWalletConnected ? 'grid-cols-2' : 'grid-cols-1'} gap-2`}>
                {isWalletConnected && (
                    <BigColorButton
                        icon={
                            <img
                                src="/gem.gif"
                                alt="gem"
                                className="w-full aspect-square absolute"
                            />
                        }
                        onClick={navigation.createToken}
                        fontSize="sm"
                    >
                        {t('main.createToken').toUpperCase()}
                    </BigColorButton>
                )}
                <BigColorButton
                    icon={
                        isWalletConnected ? (
                            <img
                                src="/ruby_heart.gif"
                                alt="heart"
                                className="w-full aspect-square absolute"
                            />
                        ) : undefined
                    }
                    variant="yellow"
                    fontSize="sm"
                    onClick={navigation.frens}
                >
                    {t('main.inviteAndEarn').toUpperCase()}
                </BigColorButton>
            </div>
            <Card className="!bg-blue bg-gradient-to-b from-white/40 to-transparent !p-4 flex flex-col gap-3">
                <div className="flex items-center w-full justify-stretch">
                    <Input
                        placeholder={t('main.searchTokenPlaceholder')}
                        onChange={setCoinListSearch}
                        value={search}
                        className="!text-2xs"
                    />
                    <Button
                        variant="blueGradient"
                        className="border-black px-4 py-2 !text-2xs border-l-0"
                        onClick={searchCoins}
                    >
                        {t('main.searchToken')}
                    </Button>
                </div>
                <Tabs tabs={tabs} activeTab={sortType} onChange={setCoinListType} />
                <CoinsList />
            </Card>
        </div>
    );
};
