import { tgChannelRegex, twitterRegex, websiteRegex } from 'createToken/config.ts';

export const tgChannelToUrl = (channel: string) => {
    const match = channel.trim().match(tgChannelRegex);
    return match ? `https://t.me/${match[1]}` : '';
};

export const twitterToUrl = (twitter: string) => {
    const match = twitter.trim().match(twitterRegex);
    return match ? `https://x.com/${match[1]}` : '';
};

export const websiteToUrl = (website: string) => {
    const match = website.trim().match(websiteRegex);
    return match ? (match[1] ? match[0] : `https://${match[0]}`) : '';
};
