import { Dictionary, useTranslation } from 'i18n';
import { navigation } from 'common/utils/routeUtils.ts';
import { useLocation } from 'wouter-preact';
import WebApp from '@twa-dev/sdk';
import { useCoinById } from 'tokens/token.store.ts';

const menuItems: {
    key: string;
    icon: string;
    text: keyof Dictionary;
    onClick: (tokenId: string) => () => void;
    match: string;
}[] = [
    {
        key: 'trade',
        icon: '/swap_horiz.svg',
        text: 'token.tabs.trade',
        onClick: navigation.tokenTrade,
        match: '/token/:tokenId/trade',
    },
    {
        key: 'info',
        icon: '/info.svg',
        text: 'token.tabs.about',
        onClick: navigation.tokenAbout,
        match: '/token/:tokenId/about',
    },
    {
        key: 'chat',
        icon: '/chat.svg',
        text: 'token.tabs.chat',
        onClick: (tgChat: string) => () => WebApp.openTelegramLink(tgChat),
        match: '/token/:tokenId/chart',
    },
];

// we need to implement the checkLocationMatch function
// it should match if location includes params as :tokenId for example
// /token/1/trade should match /token/:tokenId/trade
const checkLocationMatch = (location: string, match: string) => {
    const locationParts = location.split('/');
    const matchParts = match.split('/');
    if (locationParts.length !== matchParts.length) {
        return false;
    }
    return locationParts.every((part, index) => {
        if (matchParts[index].startsWith(':')) {
            return true;
        }
        return part === matchParts[index];
    });
};

type TokenPageMenuProps = {
    tokenId: string;
};

export const TokenPageMenu = ({ tokenId }: TokenPageMenuProps) => {
    const [location] = useLocation();
    const { t } = useTranslation();
    const { coin } = useCoinById();

    const menuItemsToRender = menuItems.filter((item) => {
        if (item.key === 'chat') {
            return !!coin?.tgChat;
        }
        return true;
    });

    return (
        <div className="sticky bottom-0 h-[100px] w-full bg-transparent z-30">
            <div className="fixed left-0 -bottom-1 bg-gray p-4 pb-10 w-screen border-black border-y h-[100px] z-50">
                <div className="flex items-center justify-between gap-3">
                    {menuItemsToRender.map((item) => (
                        <button
                            key={item.key}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (item.key === 'chat') {
                                    item.onClick(coin?.tgChat ?? '')();
                                    return;
                                }
                                item.onClick(tokenId)();
                            }}
                            className={`flex flex-1 items-center justify-center gap-1.5 border border-black text-black p-3 ${
                                checkLocationMatch(location, item.match)
                                    ? 'bg-[#79A6FF]'
                                    : 'bg-[#919191]'
                            }`}
                        >
                            <img src={item.icon} alt={item.key} className="h-4 -mt-0.5" />
                            <p className="text-sm">{t(item.text)}</p>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};
