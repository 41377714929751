import classNames from 'classnames';

type Props = {
    src: string;
    isKeyboardOpen: boolean;
    imgClassName?: string;
    imgContainerClassName?: string;
};

export const ImageContainer = ({
    src,
    isKeyboardOpen,
    imgClassName,
    imgContainerClassName,
}: Props) => {
    return (
        <div
            className={classNames('flex-col items-center justify-center mb-5', {
                [`flex h-[60%] justify-start`]: isKeyboardOpen,
            })}
        >
            <div className={classNames('flex flex-col justify-end', imgContainerClassName)}>
                <img
                    src={src}
                    className={classNames(isKeyboardOpen ? 'h-[120px]' : 'h-[200px]', imgClassName)}
                />
            </div>
        </div>
    );
};
