export const checkValidInputNumberChars = (event: KeyboardEvent) => {
    // Allowing: Integers | Backspace | Tab | Delete | Left & Right arrow keys | Single '.' or ','
    const allowedCharacter = /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/;

    const inputElement = event.target as HTMLInputElement;
    const currentValue = inputElement.value;
    const isDecimalOrComma = event.key === '.' || event.key === ',';

    // Check if '.' or ',' is already included in the value, and prevent entering another one
    if (isDecimalOrComma && (currentValue.includes('.') || currentValue.includes(','))) {
        event.preventDefault();
        return; // Stop further execution
    }

    // If the key is not an allowed character, prevent its input
    if (!event.key.match(allowedCharacter) && !isDecimalOrComma) {
        event.preventDefault();
    }
};
