import { toUserFriendlyAddress } from '@tonconnect/ui';

export const parseAddress = (address?: string) => {
    try {
        if (!address) {
            return '';
        }
        return toUserFriendlyAddress(address);
    } catch (e) {
        console.error('parseAddress', e);
        return '';
    }
};
