import { useEffect } from 'preact/hooks';
import { useTonProof } from 'tonkeeper/tonkeeper.store.ts';

export const useAutoFetch = (callback: () => void, interval?: number, delay?: number) => {
    const {isAuthorized} = useTonProof();
    useEffect(() => {
        callback();

        if (interval) {
            let intervalId: ReturnType<typeof setInterval>;
            let timeoutId: ReturnType<typeof setTimeout>;
            if (!delay) {
                intervalId = setInterval(callback, interval);
            } else {
                timeoutId = setTimeout(() => {
                    intervalId = setInterval(callback, interval);
                }, delay);
            }

            return () => {
                clearInterval(intervalId);
                clearTimeout(timeoutId);
            };
        }
    }, [isAuthorized]);
};
