import { crc32c } from '@ton/core';
import { QueryIdType } from './types';

export class BigPumpQueryId {
    private constructor(
        public readonly serviceTokenUint32: number,
        public readonly entityIdUint32: number
    ) {}

    static encode(serviceToken: string, entityIdIUint32: number) {
        const buffer = Buffer.from(serviceToken);
        const encodedString = bufferToUint32(crc32c(buffer));

        return new BigPumpQueryId(encodedString, entityIdIUint32);
    }

    static decode(data: QueryIdType): [string | null, BigPumpQueryId | null] {
        try {
            const { serviceTokenUint32, uint32 } = decodeUint64(data);
            const inst = new BigPumpQueryId(serviceTokenUint32, uint32);

            return [null, inst];
        } catch (e) {
            return ['invalid query id', null];
        }
    }

    static createFromServiceToken(serviceToken: string) {
        const randomUint32 = getRandomUint32();

        return BigPumpQueryId.encode(serviceToken, randomUint32);
    }

    isSameOrigin(originalString: string) {
        const buffer = Buffer.from(originalString);
        const encodedString = bufferToUint32(crc32c(buffer));

        return this.serviceTokenUint32 === encodedString;
    }

    toUint64() {
        return combineToUint64(this.serviceTokenUint32, this.entityIdUint32);
    }

    toString() {
        return this.toUint64().toString() as QueryIdType;
    }
}

function decodeUint64(encodedStr: string) {
    const combinedBigInt = BigInt(encodedStr);
    const serviceTokenUint32 = Number(combinedBigInt >> 32n);
    const uint32 = Number(combinedBigInt & 0xffffffffn);
    return { serviceTokenUint32, uint32 };
}

function bufferToUint32(buffer: Buffer) {
    const dataView = new DataView(buffer.buffer, buffer.byteOffset, buffer.byteLength);
    return dataView.getUint32(0, false); // Big-endian
}

function combineToUint64(encodedStringUint32: number, randomUint32: number) {
    const serviceTokenBigInt = BigInt(encodedStringUint32) << 32n;
    const randomBigInt = BigInt(randomUint32);
    const combinedBigInt = serviceTokenBigInt | randomBigInt;
    return combinedBigInt;
}

function getRandomUint32() {
    return Math.floor(Math.random() * 2 ** 32);
}
