export async function retry<T>(
    fn: () => Promise<T>,
    retries: number = 3,
    delay: number = 1000
): Promise<T> {
    let attempt = 0;

    while (attempt < retries) {
        try {
            // Attempt to execute the provided function
            return await fn();
        } catch (error) {
            attempt++;

            // If this was the last attempt, rethrow the error
            if (attempt >= retries) {
                throw error;
            }

            // Otherwise, wait for the specified delay before retrying
            await new Promise((resolve) => setTimeout(resolve, delay));
        }
    }

    // This point should not be reached; included to satisfy TypeScript
    throw new Error('Retry mechanism failed unexpectedly.');
}
