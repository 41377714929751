import { FetchController, authorizedFetcher } from './fetchUtils';

const mainServerUrl = import.meta.env.VITE_POCKETFI_SERVER_URL;
const additionalServerUrls = import.meta.env.VITE_POCKETFI_SERVER_ADDITIONAL_URLS;

const serverList = [mainServerUrl];
if (additionalServerUrls) {
    serverList.push(...additionalServerUrls.split(' '));
}

export const authorizedFetchPocketfi = authorizedFetcher(
    new FetchController(serverList, 'pocketfi')
);

export const pocketfiRoutes = {
    tc: {
        connect: '/v2/tc/connect',
        send: '/v2/tc/send',
    },
};
