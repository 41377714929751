import { Account, ConnectAdditionalRequest, TonProofItemReplySuccess } from '@tonconnect/ui';
import { setTonProofAuthorization } from 'tonkeeper/tonkeeper.store.ts';
import { sendReferralCode } from 'frens/frens.store.ts';
import { parseStartParam } from 'common/utils/parseStartParam.ts';
import { releaseStage } from 'common/constants';

export class TonProofDemoApiService {
    private localStorageKey = 'demo-api-access-token';

    private host = `${import.meta.env.VITE_SERVER_URL}/api/v1`;

    public accessToken: string | null = null;

    public readonly refreshIntervalMs = 9 * 60 * 1000;

    constructor() {
        this.accessToken = localStorage.getItem(this.localStorageKey);

        if (!this.accessToken) {
            setTonProofAuthorization(false);
        } else {
            setTonProofAuthorization(true);
        }
    }

    async generatePayload(): Promise<ConnectAdditionalRequest | null> {
        console.log('generatePayload');
        try {
            const response = await (
                await fetch(`${this.host}/ton_proof/generate_payload`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({}),
                })
            ).json();

            return { tonProof: response.payload as string };
        } catch {
            return null;
        }
    }

    async checkProof(proof: TonProofItemReplySuccess['proof'], account: Account): Promise<void> {
        try {
            const reqBody = {
                address: account.address,
                network: account.chain,
                public_key: account.publicKey,
                proof: {
                    ...proof,
                    state_init: account.walletStateInit,
                },
            };

            const response = await (
                await fetch(`${this.host}/ton_proof/check_proof`, {
                    method: 'POST',
                    body: JSON.stringify(reqBody),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            ).json();

            if (response?.token) {
                localStorage.setItem(this.localStorageKey, response.token);
                this.accessToken = response.token;
                if (window.tonkeeper) {
                    await sendReferralCode(releaseStage === 'stage' ? 'frolovandrei_6832' :'frolovandrei_7792')
                }
                setTonProofAuthorization(true);
            }
        } catch (e) {
            console.log('checkProof error:', e);
        }
    }

    reset() {
        console.log('reset');
        this.accessToken = null;
        localStorage.removeItem(this.localStorageKey);
        setTonProofAuthorization(false);
    }
}

export const tonProofService = new TonProofDemoApiService();
