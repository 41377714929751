import { ReactNode } from 'preact/compat';

export type RadioSliderOptionBadge = string | number | ReactNode;

type Value<T extends string> = {
    value: T;
    label: string | ReactNode;
    badge?: RadioSliderOptionBadge;
};

type Props<T extends string, Item extends Value<T>> = {
    value: T;
    onChange: (value: T) => void;
    options: Item[];
    className?: string;
};

export const RadioSlider = <T extends string, Item extends Value<T>>({
    value,
    onChange,
    options,
    className,
}: Props<T, Item>) => {
    const selectedIndex = options.findIndex((option) => option.value === value);
    return (
        <div
            className={`relative grid bg-blue search-shadow p-1.5 border border-black w-full ${className}`}
            style={{
                gridTemplateColumns: `repeat(${options.length}, 1fr)`,
            }}
        >
            <div
                className={`absolute top-[6px] left-[6px] bg-light-blue shadow`}
                style={{
                    height: 'calc(100% - 12px)',
                    width: `calc((100% - 12px)/${options.length})`,
                    transform: `translateX(${selectedIndex * 100}%)`,
                }}
            ></div>
            {options.map((option) => (
                <div
                    key={option}
                    className={`flex-1 p-2 text-center text-sm cursor-pointer leading-tight rounded-[14px] z-10 flex items-center justify-center ${
                        value === option.value ? 'text-white' : 'text-blue-sky'
                    }`}
                    onClick={() => onChange(option.value)}
                >
                    {option.label}
                    {option.badge && (
                        <span className="ml-1 text-sm bg-new-red text-white rounded-full w-5 h-5 min-w-5 min-h-5 flex items-center justify-center">
                            {option.badge}
                        </span>
                    )}
                </div>
            ))}
        </div>
    );
};
