import { Address, TonClient } from '@ton/ton';
import { getHttpEndpoint } from '@orbs-network/ton-access';

export class TonSdk {
    private constructor(public readonly ton: TonClient) {}

    static async new() {
        const endpoint = await getHttpEndpoint();
        const ton = new TonClient({
            endpoint,
        });

        return new TonSdk(ton);
    }

    async getBalance(address: string) {
        return this.ton.getBalance(Address.parse(address));
    }
}
