export const initTonConnect = () => {
    setTimeout(() => {
        try {
            // tonConnectUI.uiOptions = {
            //     language: language.language,
            // };
        } catch (e) {
            console.log('Failed to set language', e);
        }
    }, 1000);
};
