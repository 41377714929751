import { Card } from 'common/components/card.tsx';
import dayjs from 'dayjs';
import { Button } from 'common/components/button.tsx';
import { useState } from 'preact/hooks';
import { Drawer } from 'common/components/drawer.tsx';
import { BigColorButton } from 'common/components/bigColorButton.tsx';
import { Textarea } from 'common/components/textarea.tsx';
import { setKeyboardState } from 'common/hooks/useKeyboardOpen.ts';
import { navigation } from 'common/utils/routeUtils.ts';
import { Coin } from 'tokens/token.store.ts';
import {
    addCommunityNote,
    CommunityNote,
    fetchCommunityNotes,
    ReactionStates,
    setSortType,
    updateReaction,
    useCommunityNotes,
} from 'tokens/tabs/communityNotes.store.ts';
import { useTranslation } from 'i18n';
import classNames from 'classnames';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import WebApp from '@twa-dev/sdk';
import { Skeleton } from 'common/components/skeleton.tsx';
import { TextWithShadow } from 'common/components/textWithShadow.tsx';

const userAvatars = ['/wtfck.png', '/pepe.png', '/pepe2.png', '/trump.png'];

const getRandomAvatar = (user: string) => {
    const avatarId =
        user.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % userAvatars.length;

    return userAvatars[avatarId] as string;
};

export const CommunityNotesOnTradingTab = ({ tokenId }: { tokenId: Coin['id'] }) => {
    const { t } = useTranslation();
    const { notes, isFetched } = useCommunityNotes();

    useAutoFetch(() => fetchCommunityNotes(tokenId, 'likeCount'), 60000);

    if (!isFetched) {
        return <Skeleton size="lg" />;
    }

    return (
        <Card className="!bg-blue !p-1">
            <div className="w-full h-full bg-heavy-blue p-3 flex flex-col gap-4">
                <div className="flex items-center justify-between">
                    <h3 className="text-base text-white-gray">{t('community-notes')}</h3>
                    <button
                        className="flex items-center gap-2 text-xs text-blue-sky mt-1"
                        onClick={navigation.tokenAbout(tokenId, 'community-notes')}
                    >
                        <span>{t('community-notes.view-all')}</span>
                        <span className="p-0.5 border border-blue leading-4">{notes.length}</span>
                        <span className="text-sm">→</span>
                    </button>
                </div>
                {notes.length > 0 && <Note note={notes[0]} />}
                <AddYourNote coinId={tokenId} />
                {!notes.length && <FirstNote />}
            </div>
        </Card>
    );
};

export const CommunityNotesHeader = ({ tokenId }: { tokenId: Coin['id'] }) => {
    const { t } = useTranslation();
    const [sortDrawerOpen, setSortDrawerOpen] = useState(false);
    const { isFetched, notes, sortType } = useCommunityNotes();

    useAutoFetch(() => fetchCommunityNotes(tokenId), 60000);

    if (!isFetched) {
        return <Skeleton size="lg" />;
    }

    return (
        <Card className="!bg-blue !p-1">
            <div className="w-full h-full bg-heavy-blue p-3 flex flex-col gap-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                        <h3 className="text-base text-white-gray">{t('community-notes')}</h3>
                        <span className="text-xs text-blue-sky mt-1">{notes.length}</span>
                    </div>
                    <button className="flex items-center" onClick={() => setSortDrawerOpen(true)}>
                        <span className="text-xs text-blue-sky mt-1">
                            {t('sort-by')}{' '}
                            <strong>
                                {sortType === 'id'
                                    ? t('community-notes.sort-by.date')
                                    : t('community-notes.sort-by.likes')}
                            </strong>
                        </span>
                    </button>
                </div>
                <AddYourNote coinId={tokenId} />
                {notes.length > 0 ? (
                    notes.map((note) => <Note key={note.id} note={note} />)
                ) : (
                    <FirstNote />
                )}
            </div>
            <Drawer
                open={sortDrawerOpen}
                onClose={() => setSortDrawerOpen(false)}
                position="bottom"
            >
                <div className="flex flex-col items-center gap-3 pb-4">
                    <TextWithShadow className="italic mx-auto">
                        {t('sort-by').toUpperCase()}
                    </TextWithShadow>
                    <div className="bg-[#79A6FF] w-full border border-black border-b-4 px-3">
                        <button
                            className="w-full text-left text-xs leading-4 text-[#1A1A1A] py-2.5 flex items-center justify-between"
                            onClick={() => {
                                setSortType('likeCount');
                                fetchCommunityNotes(tokenId);
                                setSortDrawerOpen(false);
                            }}
                        >
                            <span>{t('community-notes.sort-by-button.likes')}</span>
                            {sortType === 'likeCount' && (
                                <img src="/check.svg" alt="" className="w-2.5" />
                            )}
                        </button>
                        <hr />
                        <button
                            className="w-full text-left text-xs leading-4 text-[#1A1A1A] py-2.5 flex items-center justify-between"
                            onClick={() => {
                                setSortType('id');
                                fetchCommunityNotes(tokenId);
                                setSortDrawerOpen(false);
                            }}
                        >
                            <span>{t('community-notes.sort-by-button.date')}</span>
                            {sortType === 'id' && <img src="/check.svg" alt="" className="w-2.5" />}
                        </button>
                    </div>
                </div>
            </Drawer>
        </Card>
    );
};

const AddYourNote = ({ coinId }: { coinId: Coin['id'] }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onChange = (value: string) => {
        if (value.toLowerCase().includes('fuck')) {
            setError(t('community-notes.create.error.just-dont-it'));
        } else if (value.length > 640) {
            setError(t('community-notes.create.error.too-long'));
            return;
        } else {
            setError('');
        }
        setValue(value);
    };

    const onSubmit = async () => {
        setIsLoading(true);
        await addCommunityNote({ coinId, content: value });
        setIsLoading(false);
        setValue('');
        setIsOpen(false);
    };

    return (
        <>
            <Button
                variant="blueGradient"
                className="border-black h-12"
                onClick={() => setIsOpen(true)}
            >
                {t('community-notes.add-your-note').toUpperCase()}
            </Button>
            <Drawer open={isOpen} onClose={() => setIsOpen(false)} position="bottom">
                <div className="pb-8">
                    <div className="flex gap-2.5">
                        <img
                            src="/wtfck.png"
                            alt=""
                            className="w-6 min-w-[24px] h-6 aspect-square"
                        />
                        <Textarea
                            className="!p-0 !pb-2 flex-1 text-white bg-transparent !font-normal !border-none !text-sm outline-none resize-none"
                            placeholder={t('community-notes.say-something')}
                            autoSize
                            value={value}
                            onChange={onChange}
                            onFocus={() => setKeyboardState(true)}
                            onBlur={() => setKeyboardState(false)}
                        />
                    </div>
                    {value.length > 0 && (
                        <BigColorButton
                            variant="yellow"
                            className="w-full h-12 mt-3"
                            onClick={onSubmit}
                            disabled={isLoading}
                        >
                            {t('community-notes.add-note').toUpperCase()}
                        </BigColorButton>
                    )}
                    {error && (
                        <>
                            <div className="">
                                <div className="bg-transparent opacity-0 p-2.5 text-xs text-white text-center mt-2">
                                    {error}
                                </div>
                            </div>
                            <div className="fixed bottom-0 w-screen left-0">
                                <div className="bg-light-red p-2.5 text-xs text-white text-center border-t-2 border-black">
                                    {error}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Drawer>
        </>
    );
};

const Note = ({ note }: { note: CommunityNote }) => {
    const { t } = useTranslation();
    return (
        <div className="flex items-start justify-between">
            <div className="flex items-start gap-2.5">
                <img
                    src={getRandomAvatar(note.username ?? 'anonymous')}
                    alt=""
                    className="w-9 h-9 aspect-square"
                />
                <div>
                    <div className="flex items-center gap-2">
                        <span
                            className="text-xs text-white"
                            onClick={() => {
                                if (note?.username) {
                                    WebApp.openTelegramLink('https://t.me/' + note?.username);
                                }
                            }}
                        >
                            @{note?.username ?? t('anonymous').toLowerCase()}
                        </span>
                        <span className="text-2xs font-medium text-blue-sky">
                            {dayjs.duration(dayjs(note.updatedAt).diff(dayjs())).humanize(true)}
                        </span>
                    </div>
                    <p className="font-normal text-sm text-white/80 mt-0.5 break-word w-full">
                        {note.content}
                    </p>
                </div>
            </div>
            <LikesPanel
                likeCount={note.likeCount}
                dislikeCount={note.dislikeCount}
                selfDislike={note.selfDislike}
                selfLike={note.selfLike}
                noteId={note.id}
            />
        </div>
    );
};

const LikesPanel = ({
    likeCount,
    dislikeCount,
    selfLike,
    selfDislike,
    noteId,
}: ReactionStates & { noteId: CommunityNote['id'] }) => {
    const onLikeClick = () => {
        updateReaction({ noteId, reactionType: 'like' });
    };

    const onDislikeClick = () => {
        updateReaction({ noteId, reactionType: 'dislike' });
    };

    return (
        <div className="flex items-center">
            <button
                className="flex flex-col gap-1.5 items-center aspect-square w-9"
                onClick={onLikeClick}
            >
                <img src="/like.png" alt="" className="w-4" />
                <span
                    className={classNames('text-xs text-white leading-4 rounded-full px-1', {
                        [`bg-dark-green-v2`]: selfLike,
                    })}
                >
                    {Intl.NumberFormat('en', {
                        notation: 'compact',
                        maximumFractionDigits: 0,
                    }).format(likeCount)}
                </span>
            </button>
            <button
                className="flex flex-col gap-1.5 items-center aspect-square w-9"
                onClick={onDislikeClick}
            >
                <img src="/dislike.png" alt="" className="w-4" />
                <span
                    className={classNames('text-xs text-white leading-4 rounded-full px-1', {
                        [`bg-light-red`]: selfDislike,
                    })}
                >
                    {Intl.NumberFormat('en', {
                        notation: 'compact',
                        maximumFractionDigits: 0,
                    }).format(dislikeCount)}
                </span>
            </button>
        </div>
    );
};

const FirstNote = () => {
    const { t } = useTranslation();
    return (
        <div className="flex items-center justify-between">
            <img src="/crown.png" alt="" className="w-4" />
            <p className="text-xs font-normal leading-4 text-yellow">
                {t('community-notes.your-note-will-be-first')}
            </p>
            <img src="/crown.png" alt="" className="w-4" />
        </div>
    );
};
