export const parseValue = (amount: string | bigint | number, decimal: number): number => {
    const divisor = 10 ** decimal;

    let numberAmount: number;
    if (typeof amount === 'string') {
        numberAmount = Number(BigInt(amount));
    } else if (typeof amount === 'bigint') {
        numberAmount = Number(amount);
    } else {
        numberAmount = amount;
    }

    return numberAmount / divisor;
};
