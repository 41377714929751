import { Skeleton } from 'common/components/skeleton.tsx';
import classNames from 'classnames';
import { Card } from 'common/components/card.tsx';
import {
    clearTransactions,
    Coin,
    CoinTransaction,
    fetchCoinTransactions, pullCoinTransactions,
    useCoinById,
} from 'tokens/token.store.ts';
import dayjs from 'dayjs';
import { displayNanoValue } from 'tokens/utils.ts';
import { parseValue } from 'common/utils/parseValue.ts';
import { useTranslation } from 'i18n';
import { capitalize } from 'lodash-es';
import { useCallback } from 'preact/hooks';
import { useAutoFetch } from 'common/hooks/useAutoFetch.ts';
import { useScrollToFetchData } from 'common/hooks/useScrollLazyLoad.ts';
import { EmptyList } from 'main/emptyList.tsx';
import { useCleanupEffect } from 'common/hooks/useCleanupEffect.ts';
import { squeezeAddress } from 'common/utils/squeezeAddress.ts';
import { Address } from '@ton/core';
import { CopyButton } from 'common/components/copyButton.tsx';
import WebApp from '@twa-dev/sdk';

export const TransactionHistory = ({ tokenId }: { tokenId: Coin['id'] }) => {
    const { transactions, isTransactionsFetching, canFetchMore } = useCoinById();
    const fetchTransactions = useCallback(() => fetchCoinTransactions(tokenId), [tokenId]);
    const pullTransactions = useCallback(() => pullCoinTransactions(tokenId), [tokenId]);
    useAutoFetch(fetchTransactions);
    useAutoFetch(pullTransactions, 5000, 5000);
    const anchorRef = useScrollToFetchData(fetchTransactions);
    const { t } = useTranslation();

    useCleanupEffect(clearTransactions);

    return (
        <Card className="!bg-gray !p-4 flex flex-col gap-3">
            <p className="text-white-gray text-center">{t('token.transactions.latest')}</p>
            <div className="flex flex-col gap-3">
                {isTransactionsFetching && (
                    <>
                        <Skeleton size="lg" />
                        <Skeleton size="lg" />
                        <Skeleton size="lg" />
                        <Skeleton size="lg" />
                        <Skeleton size="lg" />
                    </>
                )}
                {!isTransactionsFetching && !transactions.length && <EmptyList color="#CDCDCD" />}
                {transactions.map((transaction) => (
                    <div
                        key={transaction.txHash}
                        className="pb-3 border-b border-white-gray last:pb-0 last:border-b-0"
                    >
                        <Transaction transaction={transaction} />
                    </div>
                ))}
                <div
                    className={classNames('w-full flex flex-col gap-3', {
                        [`hidden`]: !canFetchMore,
                    })}
                    ref={anchorRef}
                >
                    <Skeleton size="lg" />
                    <Skeleton size="lg" />
                    <Skeleton size="lg" />
                    <Skeleton size="lg" />
                    <Skeleton size="lg" />
                </div>
            </div>
        </Card>
    );
};

const Transaction = ({ transaction }: { transaction: CoinTransaction }) => {
    const { t } = useTranslation();
    const { coin } = useCoinById();

    return (
        <div className="flex items-center gap-1.5">
            <img src="/pepe.png" alt="ava" className="w-9 h-9 aspect-square" />
            <div className="flex flex-col gap-0.5">
                <span
                    className="text-xs text-white"
                    onClick={() => {
                        if (transaction.user?.username) {
                            WebApp.openTelegramLink('https://t.me/' + transaction.user?.username);
                        }
                    }}
                >
                    @{transaction.user?.username ?? t('anonymous').toLowerCase()}
                </span>
                <div className="flex items-center gap-1">
                    <span className="text-xs font-normal text-white-gray">
                        {squeezeAddress(
                            Address.parse(transaction.actorAddress).toString({ bounceable: true })
                        )}
                    </span>
                    <CopyButton
                        text={Address.parse(transaction.actorAddress).toString({
                            bounceable: true,
                        })}
                    >
                        <svg
                            width={10}
                            height={10}
                            viewBox="0 0 15 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4.25 0V4H0.25V14H10.25V10H14.25V0H4.25ZM13.25 1H5.25V4H10.25V9H13.25V1ZM9.25 13V5H1.25V13H9.25Z"
                                fill="#CDCDCD"
                            />
                        </svg>
                    </CopyButton>
                </div>
                <div className="flex items-center gap-1">
                    <TransactionStatus status={transaction.type} />
                    <span className="text-2xs font-medium text-white-gray">
                        {dayjs.duration(dayjs(transaction.txUtime).diff(dayjs())).humanize(true)}
                    </span>
                </div>
            </div>
            <div className="ml-auto flex flex-col items-end">
                <span className="text-xs text-white">
                    {displayNanoValue(transaction.tonAmount)} TON
                </span>
                <span className="text-2xs text-white-gray">
                    {Intl.NumberFormat('en', {
                        notation: 'compact',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(parseValue(transaction.coinsAmount, 9))}
                    &nbsp; {coin ? coin?.symbol : ''}
                </span>
            </div>
        </div>
    );
};

type TransactionStatus = 'sell' | 'buy' | 'deploy';

const TransactionStatus = ({ status }: { status: TransactionStatus }) => {
    const { t } = useTranslation();

    const mapStatusToColor = {
        sell: 'text-bloody-red',
        buy: 'text-oxid-green',
        deploy: 'text-dark-yellow',
    };
    return (
        <span className={`text-xs ${mapStatusToColor[status]} font-medium`}>
            {capitalize(t(`token.transactions.${status}`))}
        </span>
    );
};
