import Bugsnag, { Client } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { appVersion, releaseStage } from 'common/constants';
import Webapp from '@twa-dev/sdk';

export let bugsnagClient: Client;

function initClient() {
    bugsnagClient = Bugsnag.start({
        appVersion: appVersion,
        appType: 'browser',
        releaseStage: releaseStage,
        apiKey: 'd9f647f06a0f69333f0320368a31864d',
        enabledReleaseStages: ['production', 'stage'],
        user: {
            id: Webapp.initDataUnsafe.user?.id.toString(),
            name: Webapp.initDataUnsafe.user?.username,
        },
        plugins: [new BugsnagPluginReact()],
    });
}

initClient();
