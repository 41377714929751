import { Card } from 'common/components/card.tsx';
import { Progress } from 'common/components/progress.tsx';
import { TokenHeader } from 'tokens/components/tokenHeader.tsx';
import { useCoinById } from 'tokens/token.store.ts';
import { displayNanoValue } from 'tokens/utils.ts';
import { Skeleton } from 'common/components/skeleton.tsx';
import { parseValue } from 'common/utils/parseValue.ts';
import { useTranslation } from 'i18n';
import { ChartWithControl } from 'tokens/components/chartWithControl.tsx';

export const TokenChartTab = () => {
    const { coin } = useCoinById();
    const { t } = useTranslation();

    if (!coin) {
        return <Skeleton size="lg" />;
    }

    return (
        <>
            <Card className="flex flex-col !p-3 items-center gap-2">
                <TokenHeader />
                <ChartWithControl coin={coin} />
                <div className="flex items-center w-full gap-2">
                    <span className="font-medium">{t('token.chart.marketCap')}</span>

                    <span className="font-bold">
                        ${displayNanoValue(coin.marketCap, coin.decimals)}
                    </span>
                </div>
                <Progress progress={parseFloat(coin.bondingCurveProgressTon)} />
                <div className="flex items-center w-full justify-between">
                    <span className="text-xs text-light-gray">
                        {t('token.chart.liquidityForListing')}
                    </span>
                    <div className="flex items-center gap-1">
                        <span className="text-xs">{`${parseValue(coin.tonLiqCollected, coin.decimals).toFixed(2)}/${parseValue(coin.tonSupply, coin.decimals)}`}</span>
                        <img src="/ton.svg" alt="TON" className="w-3.5 aspect-square -mt-0.5" />
                    </div>
                </div>
            </Card>
        </>
    );
};
