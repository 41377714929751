import { action, computed, map } from 'nanostores';
import { BaseEntityStore } from 'common/constants/types.ts';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { useStore } from '@nanostores/preact';
import { getSdk } from 'bigPumpSdk/sdk.store.ts';

type Activity = {
    user: {
        username: string;
        firstName: string | null;
        lastName: string | null;
    };
    coin: {
        id: string;
        name: string;
        address: string;
        symbol: string;
        imageUrl: string;
    };
    transaction: {
        id: string;
        createdAt: string;
        tonAmount: string;
        coinsAmount: string;
        type: 'sell' | 'buy';
    };
};

interface ActivityStore extends BaseEntityStore {
    activity: Activity | null;
}

export const $activity = map<ActivityStore>({
    isFetched: false,
    isLoading: false,
    activity: null,
});

// actions

export const fetchActivity = action($activity, 'fetchActivity', async (store) => {
    store.setKey('isLoading', true);

    try {
        const response = await authorizedFetch(routes.activity);

        if (response.ok) {
            const data = (await response.json()) as { activity: Activity };

            store.setKey('activity', data.activity);
            store.setKey('isFetched', true);
        }
    } catch (e) {
        console.log('fetchActivity error', e);
    } finally {
        store.setKey('isLoading', false);
    }
});

// selectors

const selectActivity = computed($activity, (store) => store);

// hooks

export const useActivity = () => useStore(selectActivity);

// --------------

interface TonStore extends BaseEntityStore {
    price: number;
}

export const $ton = map<TonStore>({
    price: 0,
    isFetched: false,
    isLoading: false,
});

// actions

export const fetchTonPrice = action($ton, 'fetchTonPrice', async (store) => {
    store.setKey('isLoading', true);

    const bigPumpSdk = getSdk();

    if (!bigPumpSdk) {
        console.log('sdk in not initialized while fetchTonPrice');
        setTimeout(() => fetchTonPrice(), 1000);
        return;
    }

    try {
        const response = await bigPumpSdk.getTonPrice();
        store.setKey('price', response.rates.TON.prices?.USD ?? 0);
        store.setKey('isFetched', true);
    } catch {
    } finally {
        store.setKey('isLoading', false);
    }
});

// selectors

const selectTon = computed($ton, (store) => store);

// hooks

export const useTon = () => useStore(selectTon);
