import { BaseEntityStore } from 'common/constants/types.ts';
import { action, computed, map } from 'nanostores';
import { authorizedFetch, routes } from 'common/utils/fetchUtils.ts';
import { useStore } from '@nanostores/preact';
import { getSdk } from 'bigPumpSdk/sdk.store.ts';

export type User = {
    id: string;
    telegramId: string;
    username?: string;
    referralCode: string;
    createdAt: string;
    joinWaitlist: boolean;
    isAlphaTest: 0 | 1;
    isAnon: boolean;
};

interface UserStore extends BaseEntityStore {
    user?: User;
    tonBalance?: bigint;
}

export const $user = map<UserStore>({
    isFetched: false,
    isLoading: false,
});

// actions

export const fetchMe = action($user, 'fetchMe', async (store) => {
    store.setKey('isLoading', true);

    try {
        const response = await authorizedFetch(routes.me);

        if (response.ok) {
            const data = (await response.json()) as { user: User };

            store.setKey('user', data.user);
            store.setKey('isFetched', true);
        }
    } catch (e) {
        console.log('fetchMe error', e);
    } finally {
        store.setKey('isLoading', false);
    }
});

export const fetchTonBalance = action($user, 'fetchTonBalance', async (store) => {
    const bigPumpSdk = getSdk();

    if (!bigPumpSdk) {
        setTimeout(() => fetchTonBalance(), 1000);
        return;
    }

    const balance = await bigPumpSdk.getTonWalletBalance();

    store.setKey('tonBalance', balance);
});

export const joinWaitlist = action($user, 'joinWaitlist', async (_) => {
    try {
        const response = await authorizedFetch(routes.referralJoinWaitlist, {
            method: 'PUT',
        });

        if (response.ok) {
            const data = (await response.json()) as { ok: number };

            if (data.ok) {
                fetchMe();
            }
        }
    } catch (e) {
        console.log('error while joining waitlist', e);
    }
});

export const changeAnonSetting = action($user, 'changeAnonSetting', async (store) => {
    try {
        const response = await authorizedFetch(routes.changeAnonSetting, {
            method: 'POST',
        });

        if (response.ok) {
            const user = store.get().user;

            if (!user) {
                return;
            }

            store.setKey('user', {
                ...user,
                isAnon: !user.isAnon,
            });
        }
    } catch (e) {
        console.log('error while changing anon setting', e);
    }
});

// selectors

const selectUser = computed($user, (store) => store);

// hooks

export const useUser = () => useStore(selectUser);
