type TickerLabelProps = {
    children: string;
    className?: string;
};

export const TickerLabel = ({ children, className }: TickerLabelProps) => {
    return (
        <span
            className={`bg-dark-yellow px-1 py-0.5 text-black text-2xs h-[17px] leading-tight font-semibold ${className}`}
        >
            {children}
        </span>
    );
};
